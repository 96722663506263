import { Component } from '@angular/core';
// I think NX linter is getting confused here. It thinks that the import shouldn't be allowed because we are already lazy-loading
// other /common-pages/xyz modules inside app.routing.ts, so it thinks it would be a waste to also compile the whole module staticaly in this import.
// However, these are standalone components, not one large common-pages module
// so the only real static import we have is the InitBaseComponent, not the entire common-pages module (we don't even have a common-pages module anymore)
// eslint-disable-next-line @nx/enforce-module-boundaries -- comments above
import { InitBaseComponent } from '@safarilaw-webapp/common-pages/init';

@Component({
  selector: 'sl-lpms-init',
  template: ''
})
export class InitComponent extends InitBaseComponent {}
