import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CoManageDataAccessObject, Company, User } from '@safarilaw-webapp/feature/co-manage/data-access';
import { NavbarComponent } from '@safarilaw-webapp/shared/app-bootstrap';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { APP_COMPANY_KEY, APP_USER_KEY } from '@safarilaw-webapp/shared/logging';
import { startWith, tap } from 'rxjs';
import { SharedObservablesDirective } from '@safarilaw-webapp/shared/ui-kit/shared-observables-directive';
import { AsyncPipe } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap/collapse';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector -- ignore NG
  selector: 'sl-bootstrap-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [RouterLink, SharedObservablesDirective, AsyncPipe, CollapseModule]
})
export class LpmsNavbarComponent extends NavbarComponent implements OnInit {
  public CoManageObject: CoManageDataAccessObject;

  constructor() {
    super();
    this.CoManageObject = this.inject(CoManageDataAccessObject);
  }
  override ngOnInit(): void {
    super.ngOnInit();

    const userFromStorage = JSON.parse(localStorage.getItem(APP_USER_KEY)) as User;

    const companyFromStorage = JSON.parse(localStorage.getItem(APP_COMPANY_KEY)) as Company;
    this.company$ = this.observeObject$(this.CoManageObject.MyCompany, SafariObject.NOID).pipe(
      startWith(companyFromStorage),

      tap(o => localStorage.setItem(APP_COMPANY_KEY, JSON.stringify(o)))
    );

    this.user$ = this.observeObject$(this.CoManageObject.MyUser, SafariObject.NOID).pipe(
      startWith(userFromStorage),

      tap(o => localStorage.setItem(APP_USER_KEY, JSON.stringify(o)))
    );
  }
}
