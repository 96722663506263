export const reduxObjectInitState = {
  list: {
    loading: [],
    loaded: [],
    saving: [],
    saved: [],
    deleting: [],
    deleted: [],

    current: [],

    failed: []
  },
  object: {
    loading: [],
    loaded: [],
    saving: [],
    saved: [],
    deleting: [],
    deleted: [],

    current: [],

    failed: []
  },
  auditHistory: {
    loading: [],
    loaded: [],
    current: [],
    failed: []
  }
};
