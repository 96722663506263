import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CoManageDataAccessObject } from '@safarilaw-webapp/feature/co-manage/data-access';

import { NavbarPermissionsService } from '@safarilaw-webapp/shared/app-bootstrap';
import { AuthService, PermissionsService, UserPermissionType } from '@safarilaw-webapp/shared/auth';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';

import { AppConfigurationService, FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { dataAccessMixin } from '@safarilaw-webapp/shared/redux';

@Injectable({
  providedIn: 'root'
})
export class LpmsNavbarPermissionsService extends dataAccessMixin(NavbarPermissionsService) {
  constructor(
    private _userPermissionService: PermissionsService,
    protected _actions: Actions,
    store: Store<any>,
    featureFlags: FeatureFlagsService,
    authService: AuthService,
    appConfig: AppConfigurationService,
    public CoManageObject: CoManageDataAccessObject
  ) {
    super(store, featureFlags, authService, appConfig);
  }
  // TODO: These are very old functions - we need to turn them into an observable
  isCurrentUserCoordinator() {
    let currentUserInfo = null;
    this.observeObject$(this.CoManageObject.MyUser, SafariObject.NOID)
      .pipe()
      .subscribe(o => {
        currentUserInfo = o;
      });
    return currentUserInfo ? this._userPermissionService.isInRole(currentUserInfo, UserPermissionType.Coordinator) : false;
  }
  // TODO: These are very old functions - we need to turn them into an observable
  isCurrentUserAdmin() {
    let currentUserInfo = null;
    this.observeObject$(this.CoManageObject.MyUser, SafariObject.NOID)
      .pipe()
      .subscribe(o => {
        currentUserInfo = o;
      });
    return currentUserInfo ? this._userPermissionService.isInRole(currentUserInfo, UserPermissionType.Admin) : false;
  }
  // TODO: These are very old functions - we need to turn them into an observable
  isCurrentUserOwner() {
    let currentUserInfo = null;
    this.observeObject$(this.CoManageObject.MyUser, SafariObject.NOID)
      .pipe()
      .subscribe(o => {
        currentUserInfo = o;
      });
    return currentUserInfo ? this._userPermissionService.isInRole(currentUserInfo, UserPermissionType.Owner) : false;
  }
  // TODO: These are very old functions - we need to turn them into an observable
  isCurrentUserAdminOrOwner() {
    let currentUserInfo = null;
    this.observeObject$(this.CoManageObject.MyUser, SafariObject.NOID)
      .pipe()
      .subscribe(o => {
        currentUserInfo = o;
      });
    return currentUserInfo ? this._userPermissionService.isInRole(currentUserInfo, UserPermissionType.Admin) || this._userPermissionService.isInRole(currentUserInfo, UserPermissionType.Owner) : false;
  }
  // TODO: These are very old functions - we need to turn them into an observable
  isCurrentUserNotAdminOrOwner() {
    return !this.isCurrentUserAdminOrOwner();
  }
  // TODO: These are very old functions - we need to turn them into an observable
  isCurrentUserUploader() {
    let currentUserInfo = null;
    this.observeObject$(this.CoManageObject.MyUser, SafariObject.NOID)
      .pipe()
      .subscribe(o => {
        currentUserInfo = o;
      });
    return currentUserInfo ? this._userPermissionService.isInRole(currentUserInfo, UserPermissionType.Uploader) : false;
  }
}
