import { Injectable } from '@angular/core';

import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { CompanyModel, UpdateCompanyProfileModel, UpdateCoordinatorModel } from '../../_comanage-api-models.generated';

import { getPhoneNumbersForApp } from '@safarilaw-webapp/shared/common-objects-models';
import { Company } from '../models/app/company';
import { CompanyCoordinator } from '../models/app/company-coordinator';

@Injectable({
  providedIn: 'root'
})
export class CompanyAdapter extends ApiDataAdapter<Company> {
  toUpdateModel(formModel: Company): SafariRequired<UpdateCompanyProfileModel> {
    const updateCoordinators: UpdateCoordinatorModel[] = [];
    if (formModel.coordinators) {
      formModel.coordinators.map(val =>
        updateCoordinators.push({
          userId: val.id as unknown as number,
          isLead: val.isLead
        })
      );
    }
    return {
      fullName: formModel.fullName,
      name: formModel.name,

      coordinators: updateCoordinators,

      requireOneTimePinDefault: formModel.requireOneTimePinDefault,
      supportContactLevel: formModel.supportContactLevel
    };
  }

  fromGetModel(model: CompanyModel): SafariRequired<Company> {
    return {
      fullName: model.fullName,
      name: model.name,
      intakeEmailDomain: model.intakeEmailDomain,
      id: model.id.toString(),
      mainTeamId: undefined,
      coordinators: model.coordinators.map(
        x =>
          ({
            id: x.userId.toString(),
            isLead: x.isLead,
            name: x.fullName,
            isActive: x.isActive,
            email: x.email,
            firstName: x.firstName,
            fullName: x.fullName,
            lastName: x.lastName,
            ...getPhoneNumbersForApp(x)
          }) as CompanyCoordinator
      ),
      domainName: null,
      applications: [],
      requireOneTimePinDefault: model.requireOneTimePinDefault,
      maxFileSizeInMB: model.maxFileSizeInMB,
      safariNumberPad: model.safariNumberPad,
      hasSsoEnabled: model.hasSsoEnabled,

      enableRetentionPolicies: model.enableRetentionPolicies,
      supportContactLevel: model.supportContactLevel,

      enableCompanyEditablePicklists: model.enableCompanyEditablePicklists,
      useServePort: model.useServePort,
      enableDataExfiltrationProtection: model.enableDataExfiltrationProtection,
      enableScim: model.enableScim,
      enableSubjectSearch: model.enableSubjectSearch,
      enableAccountLookup: model.enableAccountLookup,
      garnishmentProcessing: model.garnishmentProcessing,
      enableDeliveryPortalAcknowledgment: model.enableDeliveryPortalAcknowledgment,
      enableNotifiedTeamsTracking: model.enableNotifiedTeamsTracking,
      enableGarnishmentAutoResponse: model.enableGarnishmentAutoResponse,
      enableDocExtractor: model.enableDocExtractor
    };
  }
}
