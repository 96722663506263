import { ApplicationRef, Directive, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@safarilaw-webapp/shared/auth';
import { ApplicationInsightsService } from '@safarilaw-webapp/shared/logging';
import { first } from 'rxjs/operators';

@Directive()
export class InitBaseComponent implements OnInit {
  constructor(
    private _zone: NgZone,
    private _appRef: ApplicationRef,
    protected _router: Router,
    protected _authService: AuthService,
    private _appInsightsService: ApplicationInsightsService
  ) {}

  ngOnInit(): void {
    // This is the entry point of our angular app. Here we'll block until the app signals stable
    // for the first time.
    const appIsStable$ = this._appRef.isStable.pipe(first(isStable => isStable === true));
    // eslint-disable-next-line no-console -- We want to keep this console.log
    console.log('Init: Await idle');
    // Now go through initconfig and authredirects
    appIsStable$.subscribe(() => {
      // appIsStable runs outside of zone so we need to use zone.run when navigating.
      // It looks like we're navigating back to ourselves but we're not. The URL here will contain
      // the address that the user tried to get to (due to code in app.routing)

      // NOTE: We want to make sure that all this stuff below is running inside NG. If an error were to
      // be thrown by anything here and if we are not running in NG error handler would have no clue
      // (since it's inherently an NG construct) and the page would just sit , and we wouldn't even get
      // any sensible errors
      this._zone.run(() => {
        // eslint-disable-next-line no-console -- We want to keep this console.log
        console.log('Init: Initialize auth');
        this._authService
          .renewTokensAsPromise()
          .then(() => {
            // eslint-disable-next-line no-console -- We want to keep this console.log
            // eslint-disable-next-line no-console -- We want to keep this console.log
            console.log('Init: Initializing App Insights');
            // Let's do this here for now. If there are any additional issues we can try setTimeout or
            // move it to the constructor of feature module
            this._appInsightsService.initialize();

            if (this.checkAuth()) {
              // eslint-disable-next-line no-console -- We want to keep this console.log
              console.log('Init: Navigating', window.location.pathname);

              if (window.location.pathname.toLowerCase() == '/init') {
                // If someone for whatever reason decides to navigate to init directly
                // ,OR this is the result of maybe a chunk retry error getting resolved on init
                // page, we will just do a hard refresh back to the root.
                window.location.href = '/' + window.location.search;
                return;
              }
              this._router.navigateByUrl(window.location.pathname + window.location.search).catch(err => {
                // this may look weird but lint is forcing us to do catch on all promises
                throw err;
              });
            }
          })
          .catch(err => {
            // this may look weird but lint is forcing us to do catch on all promises
            throw err;
          });
      });
    });
  }

  protected checkAuth(): boolean {
    // Can be overridden for special authentication checks
    return true;
  }
}
