<div class="wrapper">
   <main>
      <header class="navbar">
         <div class="container s_con">
            <div id="s_navleft" class="s_sys-notice">
               <div class="s_con-title">
                  @if(errorMessage) {
                     Safari SOP Update Error
                  } @else {
                     Updating Safari SOP...
                  }

               </div>
               @if(errorMessage) {
                  <hr>
               <div class="text-danger">
                  <br>
                  <p>
                     Failed to update the application. This can be caused by a network error, VPN or proxy settings, or ad-blockers and browser extensions. <br>
                  </p>
                   <p>
                     If the error keeps occuring please contact your IT department to verify that the <b>safarisop.com</b> domain is added to the list of safe domains. 
                   </p>
       
                   <p>
                     In the meanwhile you can temporarily bypass this error by pressing CTRL and F5 at the same time (Windows) or CMD, Shift and R (Mac).
                   </p>
                  
               </div>
               }  

               <hr>
            </div>
         </div>
      </header>
   </main>
</div>