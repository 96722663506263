import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const environmentImports = [
  StoreDevtoolsModule.instrument({
    name: '[SafariLaw] - LPMS',
    trace: true,
    maxAge: 1000,
    logOnly: false,
    autoPause: true,
    // Filter actions you don't care about
    actionsBlocklist: ['[Shared] Form Update', /Get My User/.source, /Get My Company/.source, /Allow Navigation/.source, /Set Page Title/.source, /Object Saving/.source],
    connectInZone: true
  })
];
