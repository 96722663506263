import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, on, props } from '@ngrx/store';
import { SafariReduxUiObject } from '@safarilaw-webapp/shared/redux';
import { ConfirmationDialogRequest, ConfirmationDialogResponse } from '../../dialog/models/confirmation-dialog-payload';
import { IDialogState } from '../interfaces/dialog-interface';
import { ISharedState } from '../state.interface';

const getSharedFeatureState = createFeatureSelector<ISharedState>('dialogs');

const getDialogState = createSelector(getSharedFeatureState, state => state.dialogState);

@Injectable({ providedIn: 'root' })
export class AppDialogUiReduxObject extends SafariReduxUiObject<IDialogState, any> {
  // TODO: Follow example in matterpage redux object to combine into action/selector and get rid of default
  default = {
    actions: {
      modalDialogDismissed: super.createAction('Modal Dialog Dismissed', props<{ payload: ConfirmationDialogResponse }>()),
      openModalDialog: super.createAction('Open Modal Dialog', props<{ payload: ConfirmationDialogRequest }>()),
      clearDialogResponse: super.createAction('Clear Dialog Response')
    },
    selectors: {
      getDialogResponse: createSelector(getDialogState, state => state.dialogResponse),
      getDialogRequest: createSelector(getDialogState, state => state.dialogRequest)
    },
    reducers: ons => {
      ons.push(
        on(this.default.actions.modalDialogDismissed, (state: IDialogState, action: { payload: ConfirmationDialogResponse }) => ({ ...state, dialogResponse: action.payload })),
        on(this.default.actions.clearDialogResponse, (state: IDialogState) => ({ ...state, dialogResponse: null })),
        on(this.default.actions.openModalDialog, (state: IDialogState, action: { payload: ConfirmationDialogRequest }) => ({ ...state, dialogRequest: action.payload }))
      );
    }
  };
  constructor() {
    super('App', 'Dialog');
    super
      .addState({
        dialogResponse: null,
        dialogRequest: null
      })
      .finalize();
  }
}
