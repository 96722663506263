<div class="alert" style="margin-bottom: 0px;">
  <div class="modal-header ">
    <h4 class="modal-title pull-left">{{title}}</h4>
  </div>

  <div class="modal-body" [innerHTML]="content">

  </div>
  <div class="modal-footer justify-content-center">
    @if (hasOk()) {
      <button id="confirmation-dialog-ok-button" [disabled]="closeDialogRequestedBy != null" type="button" class="btn s_btn-confirmation-cancel mr-2"   [ngClass]="getOkButtonClass()"  (click)="closeModal(ConfirmationDialogButton.Ok)">
        <div [innerHTML]="okBtnName ? okBtnName : 'OK'"></div>
        @if (hasOkSubtext()) {
          <div class="s_subtext">{{okBtnSubtext}}</div>
        }
      </button>
    }
    @if (hasCancel()) {
      <button id="confirmation-dialog-cancel-button"  [disabled]="closeDialogRequestedBy != null" type="button" class="btn s_btn-confirmation-cancel mr-2" [ngClass]="getCancelButtonClass()" (click)="closeModal(ConfirmationDialogButton.Cancel)">
        <div [innerHTML]="cancelBtnName ? cancelBtnName : 'CANCEL'"></div>
        @if (hasCancelSubtext()) {
          <div class="s_subtext">{{cancelBtnSubtext}}</div>
        }
      </button>
    }
    @if (hasAux1()) {
      <button id="confirmation-dialog-aux-button"  [disabled]="closeDialogRequestedBy != null" type="button" class="btn s_btn-confirmation-aux1 mr-2" [ngClass]="getAux1ButtonClass()" (click)="closeModal(ConfirmationDialogButton.Aux1)">
        <div [innerHTML]="aux1BtnName"></div>
        @if (hasAux1Subtext()) {
          <div class="s_subtext">{{aux1BtnSubtext}}</div>
        }
      </button>
    }
  </div>

</div>
