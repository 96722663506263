import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IdToken } from '@auth0/auth0-spa-js';
import { AuthClaim, AuthService, UserVerificationService } from '@safarilaw-webapp/shared/auth';
import { combineLatest, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LpmsAuthService extends AuthService {
  public override postAuthNavigate(router: Router, redirectTargetRoute: string): void {
    const simpleUserService = this._injector.get(UserVerificationService);
    combineLatest([simpleUserService.checkForUser(), this._currentUserCompanies$.pipe(filter(o => o !== undefined))]).subscribe(([isUserFound, companies]) => {
      this._store.dispatch(this._authRO.default.actions.loginSuccess(null));
      if (isUserFound) {
        void router.navigateByUrl(redirectTargetRoute);
      } else {
        /* 404 on user implies we need to do the SSO hookup OR the user might need to choose a company.
         * If the companies endpoint comes back with 404 or userIdClaim is empty, it means we haven't done the SSO hookup yet. */
        if (companies && this.userIdClaim) {
          void router.navigate(['/select-company']);
        } else {
          void router.navigate(['/sso-init']);
        }
      }
    });
  }
  override handleTokenAndClaims(token: any, claims: any) {
    if (token && claims) {
      /* Set these values right away. Calling .login will direct outside the application, but it won't stop AuthGuard from firing first. */
      this._userIdClaimSubject$.next(this.getIdClaim(AuthClaim.UserId));
      this._companyIdClaimSubject$.next(this.getIdClaim(AuthClaim.CompanyId));
      this._auth0ConnectionClaimSubject$.next(this.getIdClaim(AuthClaim.ConnectionName));
      /* If the user requested company (currentCompanyId, from query string or localstorage) differs from the company Auth0 gave us, it means that Auth0
       * has an active session with the claimedCompanyId. The only way to get a token with the requested company then is to use a full Auth0 login with redirect.
       * There is an Auth0 community topic that makes this explicit, though unfortunately I can't find the URL right now.
       *
       * Note that skip when the user lands on /auth-callback, because this will cause Auth0 to issue a new token that might have an updated companyId
       */
      if (this._companyIdClaim && this.currentCompanyId && this.currentCompanyId != this._companyIdClaim && !location.href.includes('/auth-callback')) {
        this._loggerService.trackEvent('Login Redirect - Mismatched company preference and company claim', {
          source: 'auth.service.handleTokenAndClaims',
          appCompanyId: this.currentCompanyId,
          claimedCompanyId: this.companyIdClaim
        });
        /* Clear out the companyId we remembered. The user might be from a different company. */
        this.currentCompanyId = null;
        this.login(location.pathname + location.search);
      } else {
        this._store.dispatch(
          this._authRO.default.actions.refreshTokenSuccess({
            payload: {
              claims: claims as IdToken,
              token: token as string
            }
          })
        );
        if (this._companyIdClaim) {
          this.currentCompanyId = this._companyIdClaim;
        }

        /* Refresh the company list */
        this._store.dispatch(this._authRO.default.actions.refreshCompanies());

        this.applicationInsights.setAuthenticatedUser(this._userIdClaim, this.currentCompanyId);
      }
    }
  }
}
