import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorObject, ErrorType } from '../../models/error-object';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageParserService {
  constructor() {}

  getErrorObjectFromThrownError(thrownError: any): ErrorObject {
    // NOTE!
    // thrownError is straight from lastErrorState - it's our compound error object that contains original
    // error as-is as well as some additional information
    // Example of lastErrorState:
    //    error: { error: HttpInstanceError, status... } (we keep the original error object as-is in this error property)
    //    payload: {a:1, b:2} (original payload)
    //    someOtherOption: x (mostly this will be options object)
    //
    //
    // Therefore this object will contain error property that represents the originally thrown error.
    // Because original errors also contain error property themselves to get to the error object of the original
    // error we have to do something like thrownError.error when it's an Http error or just thrownError in case
    // of JS errors (they don't have sub "error" property)
    // To dissect that :
    // thrownError(our compound object).error(our property in which we store original error).error("error" property of the original error object)
    // And just to make things even more fun our API will return validation errors in yet another error object
    // causing a statement like thrownError.error.error a little further below

    const errorObject: ErrorObject = {
      uuid: null,
      details: [],
      safariApiError: false,
      errorType: null,

      message: '',
      jsStack: null,
      developerException: null,
      maxValidationErrors: 1,
      originalError: thrownError
    };
    try {
      const topPage = window['safari-top-page'];
      if (topPage != null && typeof topPage.getFullErrorContext == 'function') {
        errorObject.pageContext = JSON.stringify(topPage.getFullErrorContext());
      }
    } catch (err) {
      errorObject.pageContext = 'Page context failed due to: ' + (err.message as string);
    }
    errorObject.maxValidationErrors = thrownError.maxValidationErrors;
    if (thrownError.statusText && thrownError.statusText.toLowerCase() == 'navigation error') {
      errorObject.errorType = ErrorType.Navigation;
    }
    if (thrownError.status === 409) {
      errorObject.errorType = ErrorType.Conflict;
    }
    if (thrownError.status === 404) {
      errorObject.errorType = ErrorType.NotFound;
    }
    if (thrownError.status === 403 && thrownError.headers && thrownError.headers.get('content-type') && thrownError.headers.get('content-type').toLowerCase() == 'text/html') {
      errorObject.errorType = ErrorType.Firewall;
    }
    if (thrownError instanceof HttpErrorResponse) {
      if ((thrownError.error && thrownError.error.message != null) || (thrownError.error && thrownError.error.error && thrownError.error.error.message != null)) {
        // If coming back from our API then this will have additional "error" object inside of it
        // NOTE: It's important to check for presence of "message" inside the inner error object
        // If an httperror is thrown due to a completely wrong URL that will bypass .NET pipeline,
        // then there still will be an inner error object but it will be straight up HTML or something
        // else served by whatever web server is used. For example something like this.httpClient.get('fhjdsfhdk').subscribe();
        // will cause this
        const apiError = thrownError.error.error ? thrownError.error.error : thrownError.error;
        errorObject.safariApiError = true;
        errorObject.message = apiError.message;
        // This usually comes from handled errors - for example, validation
        if (apiError.details && apiError.details.length > 0) {
          errorObject.errorType = ErrorType.Validation;
          apiError.details.forEach(detail => {
            errorObject.details.push({ ...detail });
          });
        }
        if (apiError.developerException) {
          errorObject.developerException = { ...apiError.developerException };
        }
      } else {
        // This would be either an error thrown by .NET controller when no additional information is present.
        // OR an error that has completely bypassed .NET pipeline by referring to an endpoint that doesn't
        // have any .NET backend (this.httpClient.get('fhjdsfhdk').subscribe();)
        errorObject.message = thrownError.message;
      }
    } else if (typeof thrownError === 'string' || thrownError instanceof String) {
      errorObject.message = thrownError as string;
    } else {
      if (thrownError?.message?.indexOf('CKEditorError') >= 0) {
        const message: string = thrownError.message;
        const startIndex = message.indexOf('{');
        const endIndex = message.indexOf('Read more:');
        thrownError.message = message.substring(0, startIndex) + '*** REDACTED *** ';
        if (endIndex >= 0) {
          thrownError.message += message.substring(endIndex);
        }
      }

      errorObject.jsStack = thrownError.stack;
      errorObject.message = thrownError.message;
    }
    errorObject.uuid = thrownError.uuid;
    return errorObject;
  }
  getMessageHeaderFromErrorObject(errorObject, url: string): string {
    let message = '';
    if (errorObject.errorType == ErrorType.Navigation) {
      message += 'Unable to navigate to ' + url;
    } else {
      if (errorObject.errorType == ErrorType.Validation && errorObject.safariApiError) {
        message += 'Please correct the errors below and retry.';
      } else {
        message += errorObject.message;
      }
    }
    return message;
  }
  getErrorMessageFromErrorObject(o: any, suppressUnknownErrors = false) {
    if (o == null) {
      return null;
    }
    const errorObject = this.getErrorObjectFromThrownError(o);
    let message = '';
    if (errorObject.errorType == ErrorType.Validation) {
      message = errorObject.details[0].message;
    } else if (errorObject.errorType == ErrorType.Conflict) {
      message = 'Conflict error';
    } else if (errorObject.errorType == ErrorType.NotFound) {
      message = 'Not found';
    } else if (errorObject.errorType == ErrorType.Firewall) {
      message = 'Blocked by network';
    } else {
      if (suppressUnknownErrors) {
        message = 'Network Error';
      } else {
        message = errorObject.message;
      }
    }
    return message;
  }
}
