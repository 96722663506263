import { NavbarGroup, navbarGroupIdAdmin, navbarGroupIdDashboard, navbarGroupIdRequests, NavbarHeader, NavbarItem } from '@safarilaw-webapp/shared/app-bootstrap';
import { NavbarItemId } from '@safarilaw-webapp/shared/common-objects-models';
import { environmentImports } from './navbar-item-collection.env.imports';

export const NavbarItemCollection = [
  new NavbarGroup(navbarGroupIdDashboard, '', ['isAuthenticated']),
  new NavbarHeader(NavbarItemId.ActionAlerts, navbarGroupIdDashboard, 'Action Alerts', 's_mnu-link-alert', ['isAuthenticated', '!isCurrentUserUploader'], '/legal-process/action-alerts'),
  new NavbarHeader(NavbarItemId.Unassigned, navbarGroupIdDashboard, 'Unassigned', 's_mnu-link-intake', ['isAuthenticated', '!isCurrentUserUploader'], '/legal-process/intake/info-requests'),
  new NavbarHeader(
    NavbarItemId.AssignedToMe,
    navbarGroupIdDashboard,
    'Assigned to Me',
    's_mnu-link-inbox',
    ['isAuthenticated', '!isCurrentUserUploader'],
    '/legal-process/my-dashboard/assigned-to-me'
  ),
  new NavbarHeader(105, navbarGroupIdDashboard, 'All', 's_mnu-link-cabinet', ['isAuthenticated'], '/legal-process/all'),

  new NavbarGroup(navbarGroupIdRequests, '', ['isAuthenticated']),
  new NavbarHeader(200, navbarGroupIdRequests, 'Recent', 's_mnu-link-recent', ['isAuthenticated'], '/legal-process/my-dashboard/recent'),
  new NavbarHeader(201, navbarGroupIdRequests, 'Starred', 's_mnu-link-starred', ['isAuthenticated'], '/legal-process/my-dashboard/starred'),

  new NavbarGroup(navbarGroupIdAdmin, '', ['isAuthenticated']),

  new NavbarHeader(410, navbarGroupIdAdmin, 'Library', 's_mnu-link-library', ['isAuthenticated', 'isCurrentUserAdminOrOwner']),
  new NavbarItem(411, 410, 'Internal Resources', '/legal-process/library-documents'),
  new NavbarItem(412, 410, 'Templates', '/legal-process/library-templates'),
  new NavbarHeader(600, navbarGroupIdAdmin, 'Library', 's_mnu-link-library', ['isAuthenticated', 'isCurrentUserNotAdminOrOwner'], '/legal-process/library-documents'),

  new NavbarHeader(300, navbarGroupIdAdmin, 'Reports', 's_mnu-link-chart', ['isAuthenticated', '!isCurrentUserUploader']),
  new NavbarItem(302, 300, 'Reports List', '/legal-process/reports/preconfigured'),
  new NavbarItem(301, 300, 'Data Exports', '/legal-process/reports/export'),

  new NavbarHeader(505, navbarGroupIdAdmin, 'Setup', 's_mnu-link-gear', ['isCurrentUserAdminOrOwner'], '/legal-process/system-setup', null, [
    '/settings/cousers',
    '/settings/teams',
    '/settings/general/edit',
    '/legal-process/company-entities',
    '/legal-process/invoice-templates',
    '/legal-process/subfolder-groups',
    '/legal-process/subtypes',
    '/settings/registered-agent-settings',
    '/settings/intake-emails',
    '/settings/serveport-portals',
    '/legal-process/serving-parties',
    '/legal-process/matter-list-config',
    '/legal-process/date-picker-config'
  ]),
  ...environmentImports
];
