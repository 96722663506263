import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import * as appconfigurationDev from '../../appconfiguration/appconfiguration.json';
import * as appconfigurationCi from '../../appconfiguration/appconfiguration.ci.json';
import * as appconfigurationQa from '../../appconfiguration/appconfiguration.qa.json';
import * as appconfigurationPrd from '../../appconfiguration/appconfiguration.prd.json';
import * as appconfigurationDemo from '../../appconfiguration/appconfiguration.demo.json';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LpmsConfigurationService extends AppConfigurationService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  protected injectConfiguration() {
    this._injectedAppConfig = super.getCorrectEnvironment([
      appconfigurationDev.default as AppConfigurationService,
      appconfigurationCi.default as AppConfigurationService,
      appconfigurationQa.default as AppConfigurationService,
      appconfigurationDemo.default as AppConfigurationService,
      appconfigurationPrd.default as AppConfigurationService
    ]);
  }
}
