/* eslint-disable @typescript-eslint/no-unsafe-argument -- framework level */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- framwork level */
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  ApiCallContext,
  AutoRetrieveOnUpdate,
  cancelAllFiles,
  CrudOperationType,
  CrudServiceParams,
  FileObject,
  FileOperationInfo,
  FileOperationType,
  ICrudLogger,
  ObjectHistory,
  ObjectId,
  SafariObject,
  SafariObjectId,
  SafariReduxFileTransferObjectDefinition,
  TransferDialogOptions
} from '@safarilaw-webapp/shared/common-objects-models';
import * as deepDiff from 'deep-diff';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FileTransferError } from '@safarilaw-webapp/shared/error-handling-message-parser';
import { FailedFile, FailedObjectsService } from '@safarilaw-webapp/shared/failed-objects';

import { FileTransferService } from '../../../file/services/file-transfer/file-transfer.service';
import { ApiDataAdapter } from '../../adapter/adapter';
import { Collection, CrudServiceBase } from '../crud-orchestrator/crud-base.service';

enum TransferMessageType {
  Started,
  Completed,
  Failed,
  Cancelled
}
export enum ResponseType {
  HttpReponse,
  RawBlob,
  ParsedBlob
}
export class EndpointOptions {
  responseType?: ResponseType;
}
export class EndpointOverrides {
  put?: string;
  delete?: string;
  get?: string;
  getOptions?: EndpointOptions;
  create?: string;
}
export class ServiceConfiguration {
  constructor(
    public apiRoot: string,
    public apiSuffix: string,
    public updateWhiteList: string[] = [],
    public endpointOverrides: EndpointOverrides = null,
    public otherEndpoints: Record<string, string> = null
  ) {}
}
export class CrudServiceCallOptions {
  actionId: SafariObjectId;
  transferDialogOptions?: TransferDialogOptions;
  autoRetrieveOnUpdate?: AutoRetrieveOnUpdate;
  whitelistedMergePaths?: string[];
  objectType?: string;
}
/**
 * This represents crud service that operates on safariobjects (meaning it maps things like IDs, calls adapters, etc)
 * However - some of this logic is currently in crud.base.sevice so that should eventually be moved up here
 */
export class CrudService<T extends SafariObject> extends CrudServiceBase<T> {
  public otherEndpoints: Record<string, string> = null;
  private _endpointOverrides: EndpointOverrides = null;
  private _apiRoot: string = null;
  private _apiSuffix: string = null;
  private _updateWhiteList: string[] = [];
  private _fileTransferService: FileTransferService = null;
  private _store: Store<any>;
  private _actions: Actions<any>;
  private _failedObjectsService: FailedObjectsService;
  private _computedEndPoint: string;
  constructor(
    protected _adapter: ApiDataAdapter<T>,
    protected crudServiceLogger: ICrudLogger,
    serviceConfig: ServiceConfiguration = null,
    protected safariReduxFileTransferObject: SafariReduxFileTransferObjectDefinition = null
  ) {
    super();
    this._apiSuffix = serviceConfig?.apiSuffix || null;
    this._apiRoot = serviceConfig?.apiRoot || null;
    this._updateWhiteList = serviceConfig?.updateWhiteList || [];
    this._endpointOverrides = serviceConfig?.endpointOverrides || null;
    this.otherEndpoints = serviceConfig?.otherEndpoints;
    if (safariReduxFileTransferObject) {
      this._fileTransferService = this.inject(FileTransferService);
    }
    this._store = this.inject(Store);
    this._actions = this.inject(Actions);
    this._failedObjectsService = this.inject(FailedObjectsService);
  }
  get adapter() {
    return this._adapter;
  }

  protected get updateWhiteList(): string[] {
    return this._updateWhiteList;
  }
  protected set updateWhiteList(value: string[]) {
    this._updateWhiteList = value;
  }
  protected get apiRoot(): string {
    return this._apiRoot;
  }
  protected get apiSuffix(): string {
    return this._apiSuffix;
  }

  get endpoint() {
    return this._computedEndPoint || (this._computedEndPoint = `${this.apiRoot}${this.apiSuffix}`);
  }
  retrieveHistory(id: SafariObjectId): Observable<ObjectHistory> {
    const params = {
      orderBy: null,
      skip: null,
      top: 5000
    } as CrudServiceParams;
    const queryString = this.getQueryStringFromParams(params);
    return this._getHttpClient()
      .get<ObjectHistory>(`${this.endpoint}/${ObjectId(id)}/history?${queryString}`, {
        headers: this.getHeaders(),
        observe: 'response',
        reportProgress: false,
        responseType: 'json'
      })
      .pipe(
        map(o => {
          const obj = o.body;
          obj['__etag'] = o.headers.get('ETag');
          return obj;
        })
      );
  }
  // This will be removed eventually
  private _checkForParentIds(object: T, endpoint: string, useObjectId = true) {
    const id = object.id;
    // Backward compatibility - will be removed eventually
    // eslint-disable-next-line @typescript-eslint/no-deprecated -- needed for backward compatibility for now
    let parentIds: string[] = Object.prototype.hasOwnProperty.call(object, '__parentIds') ? object.__parentIds : [];
    if (SafariObject.idIsCompound(id)) {
      parentIds = [];
    }

    return SafariObject.formatEndpoint(SafariObject.id(parentIds, SafariObject.idToArray(id)), endpoint, useObjectId);
  }
  protected _getCreateEndpoint(object: T) {
    return this._checkForParentIds(object, this._getEndpoint(this._endpointOverrides?.create), false);
  }
  create(object: T & { id?: string; __parentIds?: any }, options: CrudServiceCallOptions = null, context: ApiCallContext = null): Observable<T> {
    // If this is a file object we'll defer to fileTransferService which
    // handles file upload events and dispatches them
    if ((object as unknown as FileObject)?.file instanceof File) {
      const fileObject = object as unknown as FileObject;
      const abort$ = this.getAbortPipe(options.actionId, fileObject.id, fileObject, options.transferDialogOptions, FileOperationType.Add, options.objectType);
      return this._fileTransferService
        .upload(
          this._getCreateEndpoint(object),
          fileObject.file,
          fileObject.id.toString(),
          options?.transferDialogOptions?.displayName,

          this.safariReduxFileTransferObject,
          null,

          options?.actionId.toString(),
          {
            etag: fileObject.__etag,
            formValues: fileObject.formValues,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- it's really "any", not a hack... We don't know what it could possibly be this low
            additionalInfo: fileObject.additionalInfo
          },
          fileObject.shouldUseTus,
          options?.transferDialogOptions.originalContent
        )
        .pipe(
          // upload method from fileTransferService will be returning a bunch of events, so we need to filter only
          // on the final return (HttpResponse) before we return to the caller, otherwise
          // multiple Success events will be issued.
          filter(x => x instanceof HttpResponse),
          // We'll take until either Cancel pipe or "forever" (NEVER observable).
          // This only matters if the user hits cancel before httpClient completes.
          // Once it does this whole operation is resolved and takeUntil doesn't apply
          takeUntil(abort$),
          map(() => ({ ...object }))
        );
    } else {
      // Otherwise this is a plain object. However, it still may be requesting transfer dialog
      // options for some reason. If we have those we need to set up abort pipe which allows
      // cancellations. Otherwise we'll just use NEVER emit observable
      const abort$ = options?.transferDialogOptions ? this.getAbortPipe(options.actionId, object.id, object, options.transferDialogOptions, FileOperationType.Add, options.objectType) : NEVER;

      return of(null).pipe(
        tap(() => {
          if (options?.transferDialogOptions) {
            // Dispatch the initial message
            this._dispatchTransferMessage(TransferMessageType.Started, object.id, options.transferDialogOptions, options.actionId, FileOperationType.Add);
          }
        }),
        mergeMap(
          () =>
            this._post(this._getCreateEndpoint(object), object, this.adapter, context).pipe(
              tap(() => {
                if (options?.transferDialogOptions) {
                  // Now that we're done we need to dispatch Completed message (if this was a transfer dialog operation)
                  this._dispatchTransferMessage(TransferMessageType.Completed, object.id, options.transferDialogOptions, options.actionId, FileOperationType.Add);
                }
              }),
              // We'll take until either Cancel pipe or "forever" (NEVER observable).
              // This only matters if the user hits cancel before httpClient completes.
              // Once it does this whole operation is resolved and takeUntil doesn't apply
              takeUntil(abort$),
              catchError((error: HttpErrorResponse) => {
                if (options.transferDialogOptions) {
                  // If this was transfer dialog option we add to failed object service
                  this._failedObjectsService.addFailedObject(options.objectType, {
                    originalContent: options.transferDialogOptions.originalContent || object,
                    error,
                    operation: CrudOperationType.Create
                  });
                }
                return throwError(() => error);
              })
            ) as Observable<T>
        )
      );
    }
  }
  private _getEndpoint(endpointOverride: string) {
    return endpointOverride ? this.apiRoot + endpointOverride : this.endpoint;
  }
  protected _getRetrieveEndpoint(id: SafariObjectId) {
    return SafariObject.formatEndpoint(id, this._getEndpoint(this._endpointOverrides?.get));
  }

  protected _getRetrieveAllEndpoint(parentIds: string[] = null) {
    if (parentIds == null || parentIds.length === 0) {
      return `${this.endpoint}`;
    }
    return SafariObject.formatEndpoint(SafariObject.id(parentIds, SafariObject.NOID), this._getEndpoint(this._endpointOverrides?.get));
  }
  protected _getUpdateEndpoint(object: T) {
    return this._checkForParentIds(object, this._getEndpoint(this._endpointOverrides?.put));
  }
  protected _getUpdatePartialEndpoint(object: Partial<T>) {
    return this._checkForParentIds(object as T, this._getEndpoint(this._endpointOverrides?.put));
  }
  retrieve(id: SafariObjectId, payload: T = null, context: ApiCallContext = null): Observable<T | File | HttpResponse<Blob>> {
    if (this._endpointOverrides?.getOptions?.responseType == ResponseType.RawBlob) {
      return this._retrieveRawBlobResponse(this._getRetrieveEndpoint(id));
    } else if (this._endpointOverrides?.getOptions?.responseType == ResponseType.ParsedBlob) {
      return this._retrieveParsedBlobResponse(this._getRetrieveEndpoint(id));
    }
    return this._retrieveJsonResponse(this._getRetrieveEndpoint(id), payload, this.adapter, id, context) as Observable<T>;
  }
  retrieveAll(params: CrudServiceParams = null, parentIds: string[] = [], context: ApiCallContext = null): Observable<Collection<T>> {
    const queryString = this.getQueryStringFromParams(params);
    return this._retrieveAll(this._getRetrieveAllEndpoint(parentIds) + (queryString.length > 0 ? '?' + queryString : ''), this.adapter, parentIds, context);
  }
  updateAll(originalList: T[], updatedList: T[], params: CrudServiceParams = null, parentIds: string[] = [], context: ApiCallContext = null): Observable<Collection<T>> {
    return this._updateAll(this._getRetrieveAllEndpoint(parentIds), originalList, updatedList, this.adapter, parentIds, context, params);
  }
  handleUpdateError(object: T & { id?: SafariObjectId; __parentIds?: string[] }, error: HttpErrorResponse, retryEndpoint: string, additionalWhitelist: string[]) {
    const dynamicWhiteList = additionalWhitelist == null ? [] : additionalWhitelist;

    const fullWhiteList = [...this.updateWhiteList, ...dynamicWhiteList];
    if (error.status === 409 && fullWhiteList.length > 0) {
      return this._retrieveJsonResponse(retryEndpoint, null, null).pipe(
        mergeMap(objectFromDb => {
          // extract '__etag' that was automatically applied via retrieve
          // objectFromDbCurrent will contain the original object without '__etag'.
          // Also get rid of __base that comes back from retrieve endpoint. We don't want to
          // compare those
          // eslint-disable-next-line no-unused-vars -- destructuring
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- generic type
          const { __etag, __base, ...objectFromDbCurrent } = objectFromDb;
          // get original object (before user edited anything)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- generic type
          const objectFromDbOriginal = object['__base'];
          // shallow copy object that we're trying to save so we can modify etag
          // (otherwise it would be readonly since it ultimately comes from the store)
          const objectToSave = { ...object };
          const diffs = deepDiff.diff(objectFromDbOriginal, objectFromDbCurrent);

          // if we are in conflict but both cached (__base) and current object from DB
          // are identical then this is most likely due to some integration event or
          // a child update that only updated etag of the object and nothing else.
          // In that case we can skip the logic below and go straight to updating etag
          // with the latest etag from the DB
          if (diffs != null) {
            // Check all the changes between the original object and the one that's in the DB
            // Any change that is not whitelisted is caused 'read only' and wil cause the error
            // to bubble and show "Another user has changed..."
            for (const diff of diffs) {
              if (diff.path == null) {
                return throwError(() => ({ ...error, ...{ conflictPath: null } }));
              }
              const fullPath = diff.path.join('.');

              if (!fullWhiteList.includes(fullPath)) {
                const failedMergeMessage = 'Auto-merging not attempted. Path not in whitelist path: ' + fullPath;
                // eslint-disable-next-line no-console -- We want to keep this console.log
                console.log(failedMergeMessage);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-console -- deepdiff
                console.log('DB value: ', objectFromDbOriginal[fullPath]);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-console -- deepdiff
                console.log('app value: ', objectFromDbCurrent[fullPath]);

                if (this.crudServiceLogger) {
                  this.crudServiceLogger.logAutoMergeEvent(object.id, failedMergeMessage);
                }
                // change not whitelisted - bail out
                return throwError(() => ({ ...error, ...{ conflictPath: fullPath } }));
              } else {
                const autoMergeMessage = 'Auto-merging due to save conflict in ' + fullPath;
                // eslint-disable-next-line no-console -- We want to keep this console.log
                console.log(autoMergeMessage);
                if (this.crudServiceLogger) {
                  this.crudServiceLogger.logAutoMergeEvent(object.id, autoMergeMessage);
                }
              }
            }
          } else {
            const autoMergeMessage = 'Merging due to etag diff';
            // eslint-disable-next-line no-console -- We want to keep this console.log
            console.log(autoMergeMessage);
            if (this.crudServiceLogger) {
              this.crudServiceLogger.logAutoMergeEvent(object.id, autoMergeMessage);
            }
          }
          objectToSave['__etag'] = __etag as string;

          // Let's call this recursively. Turns out there could be more changes like this
          // For example both alerts and deliveryStatus might get changed one after another
          return of(objectToSave);
        })
      );
    }
    return throwError(() => error);
  }

  update(object: T & { id?: SafariObjectId; __parentIds?: string[] }, crudServiceCallOptions: CrudServiceCallOptions = null, context: ApiCallContext = null): Observable<T> {
    let id: SafariObjectId = object.id;
    if (object['__parentIds'] != null) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated -- needed for backward compatibility for now
      id = SafariObject.id(...object.__parentIds, id);
    }

    // If we have dialog options for some reason we need to set up abort pipe which allows
    // cancellations. Otherwise we'll just use NEVER emit observable
    const abort$ = crudServiceCallOptions.transferDialogOptions
      ? this.getAbortPipe(crudServiceCallOptions.actionId, id, object, crudServiceCallOptions.transferDialogOptions, FileOperationType.Update, crudServiceCallOptions.objectType)
      : NEVER;
    return of(null).pipe(
      tap(() => {
        // Dispatch the initial message (if we requested tranfer dialog mode)
        if (crudServiceCallOptions?.transferDialogOptions) {
          this._dispatchTransferMessage(TransferMessageType.Started, id, crudServiceCallOptions.transferDialogOptions, crudServiceCallOptions.actionId, FileOperationType.Update);
        }
      }),
      mergeMap(() =>
        this._put(object, this._getUpdateEndpoint(object), this.adapter, context).pipe(
          tap(() => {
            // Dispatch Completed message (if we requested tranfer dialog mode)
            if (crudServiceCallOptions?.transferDialogOptions) {
              this._dispatchTransferMessage(TransferMessageType.Completed, object.id, crudServiceCallOptions.transferDialogOptions, crudServiceCallOptions.actionId, FileOperationType.Update);
            }
          }),
          // We'll take until either Cancel pipe or "forever" (NEVER observable).
          // This only matters if the user hits cancel before httpClient completes.
          // Once it does this whole operation is resolved and takeUntil doesn't apply
          takeUntil(abort$),
          mergeMap(o => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- getPrototypeOf returns any
            const adapterProto = this.adapter ? Object.getPrototypeOf(this.adapter) : null;

            // Now let's see if there's GET. NOTE: if adapter is not present at all then we assume GET exists.
            // Soon we'll make sure that no service can be created without adapter , but for now adding this
            // for compatibility
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- getPrototypeOf returns any
            const hasGet: boolean = adapterProto ? Object.hasOwnProperty.call(adapterProto, 'fromGetModel') : true;

            // If we are telling it to not autoretrieve OR if there is no GET method
            // to begin with then we just return what came in. I think we can start using in the majority
            // of our calls because we really just need the etag and we alreayd have that. However, we
            // have to be careful if there's anything else we want from the API, like for example some recalculated
            // properties etc. In that case we'd want to autoRetrieve after PUT.
            // Currently autoRetrieveOnUpdate is not  defaulted so if nothing is passsed in it will retrieve everything
            // (skip to the bottom of the function), but I think over time
            // as majority of our endpoints start using  AutoRetrieveOnUpdate.EtagOnly | we can then flip this to
            // default to  AutoRetrieveOnUpdate.EtagOnly |
            if (crudServiceCallOptions?.autoRetrieveOnUpdate == AutoRetrieveOnUpdate.EtagOnly || !hasGet) {
              // NOTE that if noAutoRetrieve is set if will just take what you passed in and add etag to it.
              // That's because as of right now PUT endpoints from the API always return NULL (204-NoContent)
              // However, if in the future some new PUT endpoint is added by the API and we need to get the response
              // for it we'll have to make some modifications
              return of({ ...object, __etag: o?.__etag || object.__etag });
            } else if (crudServiceCallOptions?.autoRetrieveOnUpdate == AutoRetrieveOnUpdate.Response) {
              // This will return full response and body from PUT method. Currently there is nothing that returns
              // BODY from PUT
              return of(o);
            } else if (crudServiceCallOptions?.autoRetrieveOnUpdate == AutoRetrieveOnUpdate.None) {
              // Not sure why we'd want this but the option is there. It will simply return exactly what was passed in,
              // and won't change the etag.
              return of(object);
            }
            // If none of the above then go to the object's retrieve endpoint and get everything.
            return this.retrieve(id) as Observable<T>;
          }),

          catchError((error: HttpErrorResponse) => {
            if (crudServiceCallOptions.transferDialogOptions) {
              this._failedObjectsService.addFailedObject(crudServiceCallOptions.objectType, {
                originalContent: crudServiceCallOptions.transferDialogOptions.originalContent || object,
                error,
                operation: CrudOperationType.Update
              });

              return throwError(() => error);
            }
            return this.handleUpdateError(object, error, this._getRetrieveEndpoint(id), crudServiceCallOptions ? crudServiceCallOptions.whitelistedMergePaths : []).pipe(
              mergeMap(o => this.update(o, crudServiceCallOptions, context))
            );
          })
        )
      )
    );
  }
  updatePartial(
    object: Partial<T> & { id?: SafariObjectId; __parentIds?: string[] },
    originalObject: Partial<T> & { id?: SafariObjectId; __parentIds?: string[] },
    options: CrudServiceCallOptions = null,
    context: ApiCallContext = null
  ): Observable<T> {
    let id: SafariObjectId = object['id'] as string;
    if (object['__parentIds'] != null) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated -- needed for backward compatibility for now
      id = SafariObject.id(...object.__parentIds, id);
    }
    return this._patch(object, originalObject, this._getUpdatePartialEndpoint(object), this.adapter, context).pipe(
      mergeMap(
        () =>
          // At this time we just return the object we sent. This is currently used only for bulk patching. Long
          // term I think we should have a flag (in additinoalInfo maybe) that specifies whether we want to retrieve
          // or not
          of(object) as Observable<T>
      ),

      catchError((error: HttpErrorResponse) =>
        this.handleUpdateError(object as T, error, this._getRetrieveEndpoint(id), options ? options.whitelistedMergePaths : []).pipe(
          mergeMap(o => this.updatePartial(o, originalObject, options, context))
        )
      )
    );
  }
  protected _getDeleteEndpoint(id: SafariObjectId) {
    return SafariObject.formatEndpoint(id, this._getEndpoint(this._endpointOverrides?.delete));
  }
  protected dispatchCancelMessage(actionId: SafariObjectId, id: SafariObjectId, transferDialogOptions: TransferDialogOptions, fileOperationType: FileOperationType) {
    this._dispatchTransferMessage(TransferMessageType.Cancelled, id, transferDialogOptions, actionId, fileOperationType);
    return throwError(() => ({ error: 'Cancelled', status: FileTransferError.Cancelled }));
  }

  getAbortPipe(actionId: SafariObjectId, id: SafariObjectId, object: any, transferDialogOptions: TransferDialogOptions, fileOperationType: FileOperationType, objectType: string) {
    return this._actions.pipe(
      ofType(this.safariReduxFileTransferObject.default.actions.cancelTransfer, cancelAllFiles),
      mergeMap(() => {
        if (fileOperationType == FileOperationType.Remove || fileOperationType == FileOperationType.Add || fileOperationType == FileOperationType.Update) {
          const operation =
            fileOperationType == FileOperationType.Remove ? CrudOperationType.Delete : fileOperationType == FileOperationType.Update ? CrudOperationType.Update : CrudOperationType.Create;
          const originalContent: FailedFile = {
            file: object,
            fileName: transferDialogOptions.displayName,
            metadata: transferDialogOptions.additionalInfo,

            id,
            actionId: actionId.toString()
          };
          this._failedObjectsService.addCancelledObject(objectType, {
            operation,
            error: new Error('Cancelled'),
            originalContent
          });
        }

        return this.dispatchCancelMessage(actionId, id, transferDialogOptions, fileOperationType);
      })
    );
  }

  private _dispatchTransferMessage(
    transferMessageType: TransferMessageType,
    id: SafariObjectId,
    transferDialogOptions: TransferDialogOptions,
    actionId: SafariObjectId,
    operationType: FileOperationType
  ) {
    let message = null;
    if (transferMessageType == TransferMessageType.Cancelled) {
      message = 'Cancelled';
    } else if (transferMessageType == TransferMessageType.Failed) {
      message = 'Failed';
    } else {
      message = transferDialogOptions.message;
    }

    if (message == null) {
      if (transferMessageType == TransferMessageType.Started) {
        switch (operationType) {
          case FileOperationType.Add:
            message = 'Uploading';
            break;
          case FileOperationType.Remove:
            message = 'Removing';
            break;
          case FileOperationType.Update:
            message = 'Updating';
            break;
        }
      } else if (transferMessageType == TransferMessageType.Completed) {
        switch (operationType) {
          case FileOperationType.Add:
            message = 'Uploaded';
            break;
          case FileOperationType.Remove:
            message = 'Removed';
            break;
          case FileOperationType.Update:
            message = 'Updated';
            break;
        }
      }
    }
    const info = {
      id,
      actionId,
      displayFilename: transferDialogOptions.displayName,
      secondsUntilTransferDialogShown: transferDialogOptions.secondsUntilTransferDialogShown,
      ...{
        message,
        fileOperationType: operationType,
        isError: transferMessageType == TransferMessageType.Failed || transferMessageType == TransferMessageType.Cancelled,
        percentComplete: transferMessageType == TransferMessageType.Started ? 0 : 100,
        downloadLink: null
      }
    } as FileOperationInfo;
    this._store.dispatch(this.safariReduxFileTransferObject.default.actions.updateFileUploadProgress({ payload: info }));
  }

  delete(id: SafariObjectId, body: any, endpoint: string = null, crudServiceCallOptions: CrudServiceCallOptions): Observable<any> {
    // If we have dialog options for some reason we need to set up abort pipe which allows
    // cancellations. Otherwise we'll just use NEVER emit observable
    const abort$ = crudServiceCallOptions.transferDialogOptions
      ? this.getAbortPipe(crudServiceCallOptions.actionId, id, null, crudServiceCallOptions.transferDialogOptions, FileOperationType.Remove, crudServiceCallOptions.objectType)
      : NEVER;
    return of(null).pipe(
      tap(() => {
        if (crudServiceCallOptions?.transferDialogOptions) {
          // Dispatch the initial message (if transfer dialog mode requested)
          this._dispatchTransferMessage(TransferMessageType.Started, id, crudServiceCallOptions.transferDialogOptions, crudServiceCallOptions.actionId, FileOperationType.Remove);
        }
      }),
      mergeMap(() =>
        this._delete(endpoint ? endpoint : this._getDeleteEndpoint(id), body).pipe(
          tap(() => {
            // Dispatch completed message (if transfer dialog mode requested)
            if (crudServiceCallOptions.transferDialogOptions) {
              this._dispatchTransferMessage(TransferMessageType.Completed, id, crudServiceCallOptions.transferDialogOptions, crudServiceCallOptions.actionId, FileOperationType.Remove);
            }
          }),
          // We'll take until either Cancel pipe or "forever" (NEVER observable).
          // This only matters if the user hits cancel before httpClient completes.
          // Once it does this whole operation is resolved and takeUntil doesn't apply
          takeUntil(abort$),
          map(() => ({ id })),
          catchError((error: HttpErrorResponse) => {
            if (crudServiceCallOptions.transferDialogOptions) {
              this._failedObjectsService.addFailedObject(crudServiceCallOptions.objectType, {
                originalContent: crudServiceCallOptions.transferDialogOptions.originalContent,
                error,
                operation: CrudOperationType.Delete
              });
            }
            return throwError(() => error);
          })
        )
      )
    );
  }
  deleteMultiple(id: SafariObjectId, body: any, endpoint: string = null): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- disable any check for now
    return this._delete(endpoint ? endpoint : this._getDeleteEndpoint(id), this._adapter.toDeleteMultipleModel(body)).pipe(map(() => ({ id })));
  }
}
