import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IdName } from '@safarilaw-webapp/shared/common-objects-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiDataAdapter } from '../../crud/adapter/adapter';

export class SearchService {
  constructor(private _httpClient: HttpClient, protected _endpoint: string, private _adapter: ApiDataAdapter<IdName>) {}
  private mapCollection(o: { items: IdName[] }, url: string): IdName[] {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- special param
    return o.items.map(x => this._adapter.fromListModel(x, { __url: url }));
  }

  private getHeaders(additionalHeaders = {}) {
    return new HttpHeaders({
      ...additionalHeaders
    });
  }
  protected getRetrieveUrl(url: string, baseEndpoint: string = null) {
    const endpoint = baseEndpoint == null ? this._endpoint : baseEndpoint;
    return endpoint + url;
  }
  retrieveByUrl(url: string, baseEndpoint: string = null): Observable<IdName[]> {
    url = this.getRetrieveUrl(url, baseEndpoint);

    return this._httpClient.get<any>(url, { headers: this.getHeaders() }).pipe(map((o: { items: IdName[] }) => this.mapCollection(o, url)));
  }
}
