import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
// eslint-disable-next-line @typescript-eslint/no-deprecated -- will fix later
export class AuthGuardService {
  isAuthenticated(): boolean {
    return this._authService.isAuthenticated();
  }
  get currentCompanyId(): string {
    return this._authService.currentCompanyId;
  }
  get companyIdClaim(): string {
    return this._authService.companyIdClaim;
  }
  get userIdClaim(): string {
    return this._authService.userIdClaim;
  }

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
    /* There's no token at all. User needs to go through Auth0 */
    if (!this.isAuthenticated()) {
      void this._router.navigate(['/login'], { state: { redirectTargetRoute: routerState.url } });
      return false;
    } else if (!this.companyIdClaim) {
      /* The user has a token, but there's no claimed company. This can happen for a few reasons.
       * - The user hasn't been hooked up to SSO. This user would have no userId claim either. Send them over to 'sso-init'.
       * - The user is hooked up, they have specified a company, but their token doesn't have it or the token has the wrong company.
       *   I actually don't know how a missing claim might happen, but a mismatched one may mean the user had an existing session with the previous
       *   company. Just send them through login again. This will give the requested companyId in currentCompanyId to Auth0, where the rule will either
       *   grant a token WITH the companyId claim, or it'll throw an error (see "Other cases" below).
       * - The user has access to multiple companies, but hasn't chosen one yet. The user needs to go to 'select-company'
       *
       * Other cases we don't address here:
       * - The user has no access to any companies.
       * - The user is hooked up, they have requested a bad company (one they don't have access to, or an invalid company)
       * The Auth0 rule will raise an error when they log in and they will get no token. Because we check for a missing token above with !isAuthenticated,
       * they'll never get to this branch of code. The auth-callback handler SHOULD throw them to a specialized login-error page that explains the situation,
       * and auth-guard shouldn't have to trigger.
       */
      if (!this.userIdClaim) {
        // User is not yet hooked up... If they're not going to sso-init, send them there.
        if (next.routeConfig.path != 'sso-init') {
          void this._router.navigate(['/sso-init'], { state: { redirectTargetRoute: routerState.url } });
          return false;
        }
      } else {
        // User is hooked up, and they requested a company that wasn't in their token. Send them back to Auth0 for a new token.
        if (this.currentCompanyId && (!this.companyIdClaim || this.companyIdClaim != this.currentCompanyId)) {
          this._authService.login(routerState.url);
          return false;
        }
        if (!this.currentCompanyId && next.routeConfig.path != 'select-company') {
          void this._router.navigate(['/select-company'], { state: { redirectTargetRoute: routerState.url } });
          return false;
        }
      }
    }
    return true;
  }
}
