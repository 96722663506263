import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production || environment.nonprod) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    // lint requires promises to have catch so here it is - just rethrow...

    // Also - there used to be some weird code here that was checking if error is of
    // type boolean, before treating it like an error. That was removed but we should
    // watch if there are any weird issues.
    throw err;
  });
