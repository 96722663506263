<!-- <div *ngIf="!hasNavbarHeaders()" slBootstrapNavbarRendered class="navbar--hidden placeholder"></div> -->
 
@if (hasNavbarHeaders()) {
  <!-- NOTE: Keep user observable even though it's not use. The observable is tapped to set user context for logging -->
  <ng-container *slUiKitSharedObservables="{
    user: user$ | async,
    company: company$ | async,
    allowNavigation: allowNavigation$ | async, 
    itemBadges: itemBadges$ | async } as sharedPipes; notRequired:['itemBadges', 'allowNavigation',  'user',  'company']">
    <nav id="sidebar" [class.active]="active" [class.disabled]="!sharedPipes.allowNavigation">
      <!-- BRAND -->
      <div class="sidebar-header" id="sidebarCollapse" (click)="toggleSidebar()">
        <div class="s_brand-expanded" ></div>
        <div class="s_brand-collapsed"></div>
      </div>
      @if (showCompanyName()) {
        <div class="s_mnu-header s_mnu-company">
          @if (sharedPipes.company) {
            {{ sharedPipes.company.name }}
          }
          @if (!sharedPipes.company) {
            <span style="color:transparent">_</span>
          }
        </div>
      }
      <!-- Navbar Groups -->
      <ul class="list-unstyled components">
        @for (navbarGroup of navbarGroups; track $index) {
          @if (hasGroupTitle(navbarGroup)) {
            <li class="s_mnu-header">
              {{ navbarGroup.title }}
            </li>
          }
          <!-- SPACER -->
          <li class="s_mnu-hr">
            <hr>
            </li>
            <!-- Navbar Headers -->
            @for (navbarHeader of getNavbarHeaders(navbarGroup.id); track $index) {
              <li >
                @if (hasHeaderLink(navbarHeader)) {
                  <a [routerLink]="[navbarHeader.link]" class="nav-link" [class.active]="isHeaderActive(navbarHeader.id)" [attr.id]="navbarHeader.elementId">
                    <ins></ins>
                    <span class="nav-item">{{ navbarHeader.title }}</span>
                    @if (itemBadgeValue(navbarHeader.id, sharedPipes.itemBadges) != null) {
                      <span class="badge badge-light float-right" >{{ itemBadgeValue(navbarHeader.id, sharedPipes.itemBadges) }}</span>
                    }
                  </a>
                }
                @if (!hasHeaderLink(navbarHeader)) {
                  <a style="cursor:pointer"  [attr.id]="navbarHeader.elementId"  (click)="$event.stopPropagation(); $event.preventDefault(); navbarHeader.expanded = !navbarHeader.expanded;"
                    class="dropdown-toggle" [class]="{ active: isActive(navbarHeader.id), collapsed: !isActive(navbarHeader.id) }"
                    >
                    <ins></ins>
                    <span class="nav-item">{{ navbarHeader.title }}</span>
                  </a>
                  <!-- Navbar Items -->
                  <ul class="list-unstyled collapse" [isAnimated]="true" [collapse]="!navbarHeader.expanded && !isActive(navbarHeader.id)" [attr.id]="getChildId(navbarHeader.elementId)">
                    @for (navbarSubItem of getNavbarItems(navbarHeader.id); track $index) {
                      @if (!isDivider(navbarSubItem)) {
                        <li >
                          <a [id]='getSubItemId(navbarSubItem.link)' [routerLink]="[navbarSubItem.link]"   class="nav-subitem" [class.active]="isActive(navbarSubItem.id)">
                            {{ navbarSubItem.title }}
                          </a>
                        </li>
                      }
                      @if (isDivider(navbarSubItem)) {
                        <li class="s_sub-hr">
                          <hr>
                          </li>
                        }
                      }
                    </ul>
                  }
                </li>
              }
            }
          </ul>
        </nav>
      </ng-container>
    }