import { NavbarGroup, navbarGroupIdAdmin, NavbarHeader, NavbarItem } from '@safarilaw-webapp/shared/app-bootstrap';

export const environmentImports: NavbarItem[] | NavbarGroup[] | NavbarHeader[] = [
  new NavbarHeader(99000, navbarGroupIdAdmin, 'Dev Tools', 's_mnu-link-dev', []),
  new NavbarItem(99001, 99000, 'Settings Override', '/dev-tools/settings-override'),
  new NavbarItem(99002, 99000, 'Component Template', '/dev-tools/component-template'),
  new NavbarItem(99004, 99000, 'CKEditor Demo', '/dev-tools/ckeditor-demo'),
  new NavbarItem(99014, 99000, 'NoMultiClick Demo', '/dev-tools/nomulticlick-demo'),
  new NavbarItem(99005, 99000, 'Errors', '/dev-tools/error'),
  new NavbarItem(99006, 99000, 'Expression Error', '/dev-tools/expression-changed'),
  new NavbarItem(99007, 99000, 'Clipboard Demo', '/dev-tools/clipboard-demo'),
  new NavbarItem(99008, 99000, 'Maintenance Settings', '/dev-tools/maintenance-settings'),
  new NavbarItem(99009, 99000, 'Luxon/Business Days Demo', '/dev-tools/business-days'),
  new NavbarItem(99010, 99000, 'Matter Purge', '/dev-tools/matter-purge'),
  new NavbarItem(99011, 99000, 'Dropzone Bug', '/dev-tools/dropzone-bug'),
  new NavbarItem(99013, 99000, 'Data Table', '/dev-tools/data-table')
];
