import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { FormLoadingSpinnerComponent } from '../../../forms/components/form-loading-spinner/form-loading-spinner.component';

@Directive()
export class IfNotReadyBaseDirective {
  protected propName;
  private _mainViewRef = null;
  private _spinnerRef = null;
  protected _context;
  protected _processCondition(suppressSpinner = false) {
    if (this._context.$implicit) {
      if (!this._mainViewRef) {
        this.viewContainer.clear();
        this._mainViewRef = this.viewContainer.createEmbeddedView(this.templateRef, this._context);
        this._spinnerRef = null;
      }
    } else if (!suppressSpinner) {
      if (!this._spinnerRef) {
        this.viewContainer.clear();
        const templateView = this.templateRef.createEmbeddedView(this._context);
        this._mainViewRef = null;
        this._spinnerRef = this.viewContainer.createComponent(FormLoadingSpinnerComponent, { injector: this.viewContainer.injector, projectableNodes: [templateView.rootNodes] });
      }
    }
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}
}
