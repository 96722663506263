@if (shouldShowSwWarning) {
  <h4 class="text-danger text-center">
    Warning. It looks like your service worker may be disabled. Did you use CTRL+F5 or have bypass for url checked ?
  </h4>
}

<block-ui>
  @if (!siteUnderMaintenance && !showSplash) {
    @if (shouldShowBanner()) {
      <div class="alert alert-warning alert-dismissible fade show text-center mb-0" role="alert">
        <strong>Out of date browser!</strong> Please update your browser or use the newest version of a modern browser such as <a href="https://www.google.com/chrome/">Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/">FireFox</a>, or <a href="https://www.microsoft.com/en-us/edge">Edge</a>.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    }
    <div class="wrapper" >
      @if (canShowNavbar) {
        
        <sl-bootstrap-navbar></sl-bootstrap-navbar>
      }
      <main>
        @if (siteScheduledMaintenance) {
          <sl-lpms-maintenance-header [maintenanceInfo]="maintenanceInfo"></sl-lpms-maintenance-header>
        }
        @if (canShowHeader) {
          <sl-bootstrap-header></sl-bootstrap-header>
        }
        <div [ngClass]="containerClass">
          <router-outlet ></router-outlet>
        </div>
      </main>
    </div>
  }

  @if (siteUnderMaintenance && !showSplash) {
    <sl-lpms-maintenance-splash [maintenanceInfo]="maintenanceInfo"></sl-lpms-maintenance-splash>
  }

  @if (showSplash) {
    <sl-lpms-update-splash></sl-lpms-update-splash>
  }
</block-ui>

@if (shouldIncludeRouteNavigator) {
  <div class="route-navigator" data-test="route-navigator">
    <input type="text">
    <input type="button" (click)="doNavigation($event)">
  </div>
}