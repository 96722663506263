import { SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { User } from '../co-user/models/app/user';

export abstract class CoManageHelper {
  static currentUserViewingSelf(currentUserInfo: User, id: SafariObjectId) {
    // Not sure that we use this but it was in the old code, so, let's add this
    // If the ID passed in is "me" then it means we are viewing hte profile
    if (id == 'me') {
      return true;
    }
    // Otherwise we'll compare the IDs
    if (currentUserInfo && id) {
      return currentUserInfo.id == id;
    } else {
      return false;
    }
  }

  static getCompanyLogoUrl(appConfig: AppConfigurationService, id: string) {
    return `${appConfig.urls.apiRootCoManage}companies/` + id + '/logo';
  }
}
