import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AppCustomPreloader } from '@safarilaw-webapp/shared/app-bootstrap';
import { AuthGuardService } from '@safarilaw-webapp/shared/auth';

import { CHUNK_RETRY_COUNT_PARAM } from '@safarilaw-webapp/shared/error-handling';
import { environmentImports } from './app.routing.env.imports';
import { InitComponent } from './init/init.component';
const routes: Routes = [
  // This path used to point to "legal-process" which in turn used to load feature module
  // Now we are going to go straight to feature module, and that one internally will have
  // children that represent previous top level URLS (legal-process vs settings)
  {
    path: '',
    loadChildren: () => import('@safarilaw-webapp/feature/lpms/ui').then(m => m.FeatureLpmsModuleUI),
    canActivate: [AuthGuardService]
  },

  ...environmentImports,
  // COPY THIS FOR ALL NEW APPS
  // Routes for shared-common-pages
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: 'interstitial',
    loadComponent: () => import('@safarilaw-webapp/common-pages/interstitial').then(a => a.InterstitialComponent),
    // component: InterstitialComponent,
    data: { hideNavbar: true, hideHeader: true, operationName: 'Interstitial' }
  },
  {
    path: 'auth-callback',
    loadComponent: () => import('@safarilaw-webapp/common-pages/auth-callback').then(a => a.AuthCallbackComponent),
    // component: AuthCallbackComponent,
    data: { hideNavbar: false, hideHeader: true, operationName: 'Auth Callback' }
  },
  {
    path: 'logout',
    loadComponent: () => import('@safarilaw-webapp/common-pages/logout').then(a => a.LogoutComponent),
    // component: LogoutComponent,
    data: { hideHeader: true, suppressContainer: true, operationName: 'Logout' }
  },
  {
    path: 'clear',
    loadComponent: () => import('@safarilaw-webapp/common-pages/clear-storage').then(a => a.ClearStorageComponent),
    // component: ClearStorageComponent,
    data: { operationName: 'Clear Local Storage' }
  },
  {
    path: 'login-error',
    loadComponent: () => import('@safarilaw-webapp/common-pages/login-error').then(a => a.LoginErrorComponent),
    // component: LoginErrorComponent,
    data: { hideHeader: true, suppressContainer: true, hideNavbar: true, operationName: 'Login - Error' }
  },
  {
    path: 'login-no-user',
    loadComponent: () => import('@safarilaw-webapp/common-pages/login-no-user').then(a => a.LoginNoUserComponent),
    //    component: LoginNoUserComponent,
    data: { hideHeader: true, suppressContainer: true, hideNavbar: true, operationName: 'Login - No User' }
  },
  {
    path: 'error',
    loadComponent: () => import('@safarilaw-webapp/common-pages/page-error').then(a => a.PageErrorComponent),
    //   component: PageErrorComponent,
    data: { pageTitle: 'Request Failure', operationName: 'Error' }
  },
  {
    path: 'login',
    loadComponent: () => import('@safarilaw-webapp/common-pages/login').then(a => a.LoginComponent),
    // component: LoginComponent,
    data: { hideNavbar: true, hideHeader: true, suppressContainer: true, operationName: 'Login' }
  },
  {
    path: '403',
    loadComponent: () => import('@safarilaw-webapp/common-pages/unauthorized').then(a => a.UnauthorizedComponent),
    //  component: UnauthorizedComponent,
    data: { hideHeader: true, suppressContainer: true, operationName: 'Not Authorized' }
  },
  {
    path: '404',
    loadComponent: () => import('@safarilaw-webapp/common-pages/page-not-found').then(a => a.PageNotFoundComponent),
    //  component: PageNotFoundComponent,
    data: { hideHeader: true, suppressContainer: true, operationName: 'Page Not Found' }
  },
  {
    path: '504',
    loadComponent: () => import('@safarilaw-webapp/common-pages/gateway-timeout').then(a => a.GatewayTimeoutComponent),
    //  component: GatewayTimeoutComponent,
    data: { hideHeader: true, suppressContainer: true, operationName: 'Gateway Timeout' }
  },
  {
    path: 'termsofservice',
    loadComponent: () => import('@safarilaw-webapp/common-pages/terms-of-service').then(a => a.TermsOfServiceComponent),
    //   component: TermsOfServiceComponent,
    data: { pageTitle: 'Terms & Privacy', operationName: 'Terms Of Service' }
  },

  {
    path: 'sso-init',
    loadComponent: () => import('@safarilaw-webapp/common-pages/co-user-sso-init').then(a => a.CoUserSsoInitComponent),
    // component: CoUserSsoInitComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    data: { hideNavbar: true, hideHeader: true, operationName: 'SSO Init' }
  },
  {
    path: 'select-company',
    loadComponent: () => import('@safarilaw-webapp/common-pages/co-user-select-company').then(a => a.CoUserSelectCompanyComponent),
    // component: CoUserSelectCompanyComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    data: { hideNavbar: true, hideHeader: true, operationName: 'Select Company' }
  },
  { path: 'init', component: InitComponent, data: { hideNavbar: true, hideHeader: true } },
  {
    path: '**',
    loadComponent: () => import('@safarilaw-webapp/common-pages/page-not-found').then(a => a.PageNotFoundComponent),
    // component: PageNotFoundComponent,
    data: { hideHeader: true, suppressContainer: true, operationName: 'Page Not Found' }
  }

  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////
];

@NgModule({
  // 'legacy' was auto-added by angular during conversion but we should review if we want that. The new 'corrected'
  // option is the default, but looks like they want to be safe during migration and they add 'legacy'. We will probably need to
  // retest all links if we do so.

  // NAVBAR CHANGE - to rollback delete onSameUrlNavigation parameter
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      preloadingStrategy: AppCustomPreloader,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule],
  providers: [AppCustomPreloader]
})
export class AppRoutingModule {
  constructor(router: Router) {
    const url = new URL(window.location.href);

    // Don't blindly navigate to init. In normal circumstances the module should never load with
    // "init" as its startup path, but it could if this happened during chunkload error. So if that's
    // the case make sure you pass the chunk count
    if (url.pathname.toLowerCase() == '/init') {
      const chunkCount = parseInt(url.searchParams.get(CHUNK_RETRY_COUNT_PARAM), 10);
      if (!isNaN(chunkCount)) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- don't want to catch this
        router.navigate(['init'], {
          queryParams: {
            // eslint-disable-next-line @typescript-eslint/naming-convention -- this is correct name
            __chcn: chunkCount
          }
        });
        return;
      }
    }
    // Otherwise this is a normal flow so go to init component but don't change the location
    void router.navigate(['/init'], { skipLocationChange: true });
  }
}
